import * as React from "react"
import { Link } from "gatsby"
import Button from "../Button/Button"

const Feature = ({ name, slug, image, excerpt, linkTitle }) => {
  return (
    <aside className="features__item">
      <Link to={slug}>
        {image}
        <div className="features__item--content">
          {name && <h2>{name}</h2>}
          {excerpt && <p>{excerpt}</p>}
          <Button text={linkTitle} as="span" />
        </div>
      </Link>
    </aside>
  )
}

export default Feature
