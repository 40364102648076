import * as React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/SEO"
import Products from '../components/Products/Products'
import SimpleBanner from "../components/SimpleBanner/SimpleBanner"

const data = [
  {
    name: "Arkadiusz Malinowski",
    slug: "/band/arkadiusz",
    image: <StaticImage className="features__item--img" src="../images/band/arek-thumb.jpg"/>,
    excerpt:
      "Założyciel Memento Group. Wokalista, klawiszowiec, kompozytor, autor tekstów, w zespole pełni funkcję prowadzącego / wodzireja oraz dba o kwestie techniczne.",
    linkTitle: "Arkadiusz",
  },
  {
    name: "Jagoda Szydłowska",
    slug: "/band/jagoda",
    image: <StaticImage className="features__item--img" src="../images/band/jagoda-thumb.jpg"/>,
    excerpt:
      "To osoba z wieloletnim doświadczeniem wokalnym i obyciem scenicznym. Od lat śpiewa i występuje na różnych eventach i koncertach.",
    linkTitle: "Jagoda",
  },
  {
    name: "Tomasz Kriger",
    slug: "/band/tomasz",
    image: <StaticImage className="features__item--img" src="../images/band/tomasz-thumb.jpg"/>,
    excerpt:
      "Nikt nie jest w stanie oprzeć się wdziękowi Tomasza, który swoimi wykonami potrafi ekspresowo zapełnić cały parkiet, a jego gra na gitarze to gratka dla każdego melomana!",
    linkTitle: "Tomasz",
  },
  {
    name: "Hubert Sobiecki",
    slug: "/band/hubert",
    image: <StaticImage className="features__item--img" src="../images/band/hubert-thumb.jpg"/>,
    excerpt:
      "Saksofonista z klasycznym warsztatem i bogatym doświadczeniem. Ekspresywny, nieustannie doskonalący swoje umiejętności improwizacji.",
    linkTitle: "Hubert",
  },
  {
    name: "Zuzanna Janczak",
    slug: "/band/zuzanna",
    image: <StaticImage className="features__item--img" src="../images/band/zuzanna-thumb.jpg"/>,
    excerpt:
      "Skrzypaczka, ukończyła z wyróżnieniem Akademię Muzyczną w Krakowie oraz Królewskie Konserwatorium Muzyczne w Brukseli. Koncertuje w Polsce, Europie i Stanach Zjednoczonych, laureatka wielu-konkursów skrzypcowych w Polsce i Europie.",
    linkTitle: "Zuzanna",
  },
  {
    name: "Mateusz Łężniak",
    slug: "/band/mateusz",
    image: <StaticImage className="features__item--img" src="../images/band/mateusz-thumb.jpg"/>,
    excerpt:
      "Absolwent Akademii Muzycznej w Krakowie na wydziale Jazzu w klasie saksofonu. Muzyk z wieloletnim doświadczeniem estradowym i eventowym.",
    linkTitle: "Mateusz",
  },
]

const band = () => {
  return (
    <>
      <Seo title="Nasz zespół" />
      <Layout>
        <SimpleBanner title="Poznaj nasz zespół">
          <StaticImage
            className="banner__image desktop"
            src="../images/band/big.jpg"
          />
        </SimpleBanner>

        <Products products={data}/>
      </Layout>
    </>
  )
}

export default band
