import * as React from "react"
import { ProductsStyles } from "./ProductsStyles"
import Product from "./Product"

const Features = ({ products = [] }) => {
  return (
    <ProductsStyles>
      <div className="features__container">
        <div className="features__container--scroll">
          {products.map(product => {
            return <Product {...product} />
          })}
        </div>
      </div>
    </ProductsStyles>
  )
}

export default Features
